<template>
  <div class="bg-default-bg bg-fixed h-dvh overflow-scroll">
    <div id="main" class="flex-grow min-w-full min-h-screen relative">
      <button
        v-if="isLoggedIn"
        class="lg:hidden top-0 z-10 rounded-br-full bg-pink-300 absolute inline-flex items-center justify-center pr-4 pb-4 pl-2 pt-4 rounded-md text-white hover:text-pink-300 hover:bg-white focus:outline-none"
        @click="sidebarOpen = true"
      >
        <Icon
          name="material-symbols:menu-rounded"
          class="block h-6 w-6"
          aria-hidden="true"
        />
      </button>
      <div>
        <TransitionRoot as="template" :show="sidebarOpen">
          <Dialog
            as="div"
            class="relative z-50 lg:hidden"
            @close="sidebarOpen = false"
          >
            <TransitionChild
              as="template"
              enter="transition-opacity ease-linear duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="fixed inset-0 bg-gray-900/80" />
            </TransitionChild>

            <div class="fixed inset-0 flex">
              <TransitionChild
                as="template"
                enter="transition ease-in-out duration-300 transform"
                enter-from="-translate-x-full"
                enter-to="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leave-from="translate-x-0"
                leave-to="-translate-x-full"
              >
                <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                  <TransitionChild
                    as="template"
                    enter="ease-in-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in-out duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                  >
                    <div
                      class="absolute left-full top-0 flex w-16 justify-center pt-5"
                    >
                      <button
                        type="button"
                        class="-m-2.5 p-2.5"
                        @click="sidebarOpen = false"
                      >
                        <span class="sr-only">Close sidebar</span>
                      </button>
                    </div>
                  </TransitionChild>
                  <!-- Sidebar component, swap this element with another sidebar if you like -->
                  <div
                    class="flex h-full grow flex-col gap-y-5 overflow-y-auto px-3 pb-2 bg-gradient-to-r from-sky-200 to-teal-100"
                  >
                    <Sidebar class="p-4 mt-4" />
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </Dialog>
        </TransitionRoot>

        <main class="shadow-md lg:mx-36 relative">
          <div
            class="hidden lg:block loader h-full w-full overflow-clip absolute pointer-events-none z-10"
          >
            <span v-for="n in 20" :key="n" />
          </div>

          <navbar />

          <div class="flex from-white/60 via-white/80 to-white/60">
            <div
              class="hidden bg-gradient-to-r from-white/60 via-white/80 to-white/60 lg:block lg:w-10"
            />
            <div
              class="bg-gradient-to-r from-white/60 via-white/80 to-white/60 h-full"
            />
            <div class="hidden max-w-[25%] lg:flex lg:flex-col">
              <div class="p-4 mt-2">
                <Sidebar />
              </div>
              <div
                class="bg-gradient-to-r from-white/60 via-white/60 to-white/60 h-full"
              />
            </div>
            <div class="flex flex-col flex-grow">
              <div
                class="bg-gradient-to-r from-white/60 via-white/80 to-white/60 w-full min-h-screen flex-grow px-4"
              >
                <slot class="w-full" />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Navbar from "~/components/structure/Navbar.vue";
import Sidebar from "~/components/structure/Sidebar.vue";

import { ref } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const router = useRouter();

const sidebarOpen = ref(false);

const { isLoggedIn } = storeToRefs(usePocketBase());

watch(
  () => router.currentRoute.value,
  () => {
    sidebarOpen.value = false;
  },
);
</script>

<style>
.body {
  height: 100%;
}

.loader {
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.loader span {
  width: 20px;
  height: 21px;
  /*   margin: -280px 60px 54px  -34px; */
  background: url("public/layout/rose.png");
  opacity: 0;
  -webkit-animation: loader 10s infinite linear;
  -moz-animation: loader 10s infinite linear;
  animation: loader 10s infinite linear;
}

.loader span:nth-child(5n + 5) {
  -webkit-animation-delay: 1.3s;
  -moz-animation-delay: 1.3s;
  animation-delay: 1.3s;
}

.loader span:nth-child(3n + 2) {
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.loader span:nth-child(2n + 5) {
  -webkit-animation-delay: 1.7s;
  -moz-animation-delay: 1.7s;
  animation-delay: 1.7s;
}

.loader span:nth-child(3n + 10) {
  -webkit-animation-delay: 2.7s;
  -moz-animation-delay: 2.7s;
  animation-delay: 2.7s;
}

.loader span:nth-child(7n + 2) {
  -webkit-animation-delay: 3.5s;
  -moz-animation-delay: 3.5s;
  animation-delay: 3.5s;
}

.loader span:nth-child(4n + 5) {
  -webkit-animation-delay: 5.5s;
  -moz-animation-delay: 5.5s;
  animation-delay: 5.5s;
}

.loader span:nth-child(3n + 7) {
  -webkit-animation-delay: 8s;
  -moz-animation-delay: 8s;
  animation-delay: 8s;
}

@keyframes loader {
  0% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(0, 0vh) rotateZ(0deg);
  }
  25% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(0, 25vh) rotateZ(90deg);
  }
  75% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(5vw, 75vh) rotateZ(270deg);
  }
  100% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
  }
}

@-webkit-keyframes loader {
  0% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(0, 0vh) rotateZ(0deg);
  }
  25% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(0, 25vh) rotateZ(90deg);
  }
  75% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(5vw, 75vh) rotateZ(270deg);
  }
  100% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
  }
}

@-moz-keyframes loader {
  0% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(0, 0vh) rotateZ(0deg);
  }
  25% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(0, 25vh) rotateZ(90deg);
  }
  75% {
    width: 20px;
    height: 21px;
    opacity: 1;

    -webkit-transform: translate(5vw, 75vh) rotateZ(270deg);
  }
  100% {
    width: 20px;
    height: 21px;
    opacity: 0;

    -webkit-transform: translate(10vw, 100vh) rotateZ(360deg);
  }
}
</style>
