<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div
    class="flex justify-center w-full relative bg-gradient-to-r from-white/60 via-white/40 to-white/60"
  >
    <nuxt-link to="/">
      <img src="public/layout/header.png" alt="" />
    </nuxt-link>
    <div class="absolute flex justify-start top-[5%] right-[20%]">
      <login />
      <Menu v-if="userWitch" as="div" class="relative">
        <div>
          <MenuButton
            class="flex text-sm rounded-full border-purple-200 border-2"
          >
            <span class="sr-only">Open user menu</span>
            <div class="bg-opacity-5 rounded-full">
              <img
                v-if="userWitch"
                class="h-16 w-16 rounded-full"
                :src="avatarUrl"
                alt=""
              />
              <div
                v-else
                class="h-10 w-10 rounded-full bg-white flex items-center justify-center text-xl font-star"
              >
                ?
              </div>
            </div>
          </MenuButton>
        </div>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white bg-opacity-[30%] text-slate-500 ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <MenuItem v-slot="{ active }">
              <router-link
                active-class="text-gray-200"
                :to="`/witch/${userWitch?.id}/profile`"
                :class="{ 'text-gray-500': active }"
                class="block px-4 py-2 text-sm text-gray-400"
                >Dein Profil
              </router-link>
            </MenuItem>
            <!--                  <MenuItem v-slot="{ active }">-->
            <!--                    <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Einstellungen</a>-->
            <!--                  </MenuItem>-->
            <MenuItem v-slot="{ active }">
              <a
                href="#"
                :class="{ 'text-gray-500': active }"
                class="block px-4 py-2 text-sm text-gray-400"
                @click="logout"
                >Ausloggen</a
              >
            </MenuItem>
            <MenuItem v-slot="{ active }">
              <router-link
                active-class="text-gray-200"
                to="/user/news"
                :class="{ 'text-gray-500': active }"
                class="block px-4 py-2 text-sm text-gray-400"
                >News
              </router-link>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import Login from "@/pages/user/Login.vue";
import useUserStore from "~/stores/useUserStore";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { userWitch } = storeToRefs(userStore);
const pocketBaseStore = usePocketBase();
const { logout } = pocketBaseStore;
const { pocketbase } = storeToRefs(pocketBaseStore);

const avatarUrl = computed<string | undefined>(() => {
  if (!userWitch.value) {
    return undefined;
  }

  return pocketbase.value.getFileUrl(userWitch.value, userWitch.value?.avatar);
});
</script>
