<template>
  <div class="flex flex-col gap-7">
    <place-card
      v-if="hasTeacherAccess"
      name="Lehrer Zimmer"
      :routes="TeacherRoutes"
      class="w-full"
    />
    <template v-if="userWitch">
      <place-card name="Kanaka Valley" :routes="KanakaRoutes" class="w-full" />
      <place-card name="Pelaia" :routes="PelaiaRoutes" class="w-full" />
      <place-card name="Huna City" :routes="HunaCityRoutes" class="w-full" />
    </template>
    <place-card name="Infos" :routes="InfoRoutes" class="w-full" />
    <place-card name="Sonstiges" :routes="OtherRoutes" class="w-full" />
  </div>
</template>

<script setup lang="ts">
import PlaceCard from "@/components/places/PlaceCard.vue";

const { hasTeacherAccess } = storeToRefs(usePocketBase());
const { userWitch } = storeToRefs(useUserStore());

const TeacherRoutes = [
  {
    route: "/teacher/exams",
    name: "Prüfungen",
  },
  {
    route: "/teacher/applications",
    name: "Bewerbungen",
  },
];

const KanakaRoutes = [
  {
    route: "/places/playground",
    name: "Spielplatz",
  },
  {
    route: "/to-do",
    name: "Schwimmbad",
  },
];

const InfoRoutes = [
  {
    route: "/places/rules",
    name: "Regeln",
    icon: "/icons/044-magic-book.png",
  },
  {
    route: "/places/witch-list",
    name: "Schülerliste",
    icon: "/icons/011-hat.png",
  },
];

const PelaiaRoutes = [
  {
    route: "/to-do",
    name: "HexenListe",
  },
  {
    route: "/places/exam-hall",
    name: "Prüfungshalle",
    icon: "/icons/036-exam.png",
  },
  {
    route: "/to-do",
    name: "Schulzentrum",
  },
  {
    route: "/to-do",
    name: "Joilant",
  },
  {
    route: "/to-do",
    name: "Waipunawiese",
  },
  {
    route: "/to-do",
    name: "Schützlingsheim",
  },
];

const HunaCityRoutes = [
  {
    route: "/places/magical-tree",
    name: "Magischer Baum",
  },
  {
    route: "/to-do",
    name: "Stadtpark",
  },
  {
    route: "/to-do",
    name: "Stadtzentrum",
  },
  {
    route: "/to-do",
    name: "Außenbezirk",
  },
  {
    route: "/places/shopping-district",
    name: "Einkaufsstraße",
    icon: "/icons/031-red-envelope-1.png",
  },
];

const OtherRoutes = [
  {
    name: "About",
    route: "/about/mizu-and-temi",
  },
];
</script>
